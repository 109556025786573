import * as firebase from "firebase/app";

import React, { Component } from "react";
import ContentLoader from "react-content-loader";
import DelayLink from "../../helpers/delayLink";

import Footer from "../Footer";
import Meta from "../../helpers/meta";
import Nav from "../Nav";

import { Redirect } from "react-router";
import { connect } from "react-redux";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import messaging from "../../../init-fcm";
import {getCategory} from "../../../services/items/actions";
// import { getSingleLanguageData } from "../../../services/languages/actions";
import { getPromoSlides } from "../../../services/promoSlider/actions";
import { getPromoBanners } from "../../../services/promoSlider/actions";
import { getBrand } from "../../../services/items/actions";
import { topShoppingOffers } from "../../../services/items/actions";
import { shopOurServices } from "../../../services/items/actions";
import { todayDeals } from "../../../services/items/actions";
import { fashionOffers } from "../../../services/items/actions";
import { featuredProducts } from "../../../services/items/actions";
import { topOfTheDay } from "../../../services/items/actions";

import Categorywise from "./Categorywise";
import PromoSlider from "./PromoSlider";
import PromoBanner from "./PromoBanner";
import TopShoppingOffers from "./TopShoppingOffers";
import Brand from "./Brand";
import ShopOurServices from "./ShopOurServices";
import FashionOffers from "./FashionOffers";
import TodayDeals from "./TodayDeals";
import FeaturedProducts from "./FeaturedProducts";
import TopOfTheDay from "./TopOfTheDay";

import { GET_TESTIMONIAL_CONTENT_URL , keyValue } from "../../../configs/index";

import { TESTIMONIAL_IMAGE_URL, DEFAULT_IMG  } from "../../../configs/index";

import axios from "axios";

class Home extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			
			loading: true,
			pagedata : [],
			headerName : "Testimonials",
			update: true,
			responsive:{
				320: {
					items: 1,
				},
				575: {
					items: 1,
				},
				768: {
					items: 2,
				},
				1024: {
					items: 3,
				},
				1199: {
					items: 3,
				},
			},
			
			
			
		};

    }	
	
	static contextTypes = {
		router: () => null,
	};

	 componentDidMount() {
		// this.props.getSingleLanguageData(1);
		this.props.getCategory();
		this.props.getPromoSlides();
		this.props.getPromoBanners();
		this.props.getBrand();
		this.props.topShoppingOffers();
		this.props.shopOurServices();
		this.props.todayDeals();
		this.props.fashionOffers();
		this.props.featuredProducts();
		this.props.topOfTheDay();
		axios.defaults.headers.common = {
			"X-API-Key": keyValue,
		  };
		  axios.post(GET_TESTIMONIAL_CONTENT_URL, {
			  
		  }).then((response) => {					
			  this.setState({ loading: false });
			  console.log(response.data);
			  if(response.data.success == 1){		
				  this.setState({ pagedata : response.data.testimonial });	
			  }		
		  })
		  .catch(function(error) {
			  console.log(error);
		  });
		
		
	}
	componentWillReceiveProps(nextProps) {
		
		
	}



	componentWillUnmount() {
		// navigator.serviceWorker.removeEventListener("message", message => console.log(message));
	}
	

	render() {		

		const { history, user, promo_slides, get_brand, get_category, promo_banners, top_shopping_offers, shop_our_services } = this.props;
		const { today_deals, fashion_offers, featured_products, top_of_the_day } = this.props;
		const { loading, pagedata } = this.state;
		const DEFAULT_IMG="/assets/img/user.png"
		console.log(pagedata);
		console.log(pagedata.length);
		if (loading) {
			return (
				<div className="height-100 overlay-loading" id="login-loader">
					<div>
						<img src="/assets/img/loading-food.gif" alt='Please wait' />
					</div>
				</div>
			);				
		}
		
		/*console.log(get_category);		
		console.log(get_category.cat);
		
		console.log(promo_slides);
		console.log(promo_slides.success);
		
		console.log(promo_banners);
		console.log(promo_banners.success);
		
		console.log(get_brand);
		console.log(get_brand.success);
		*/
		return (
			<React.Fragment>
				<Meta />
				<Nav />
				

				{(promo_slides.success == 1) ?
					<PromoSlider slides={promo_slides.sliders} />
					: '' }

					<section className="homeAbout_wrapper">
						<div className=" homeAbout_holder">
							<div className="row">
								<div className="col-md-5">
									<div className="img-holder">
										<img src="/assets/img/about-img-3.png"/>
									</div>
								</div>

								<div className="col-md-7">
<div className="homeAbout_wrapper">
									<div className="homeAbout_content">
									<div class="title-section">
<div class="section_heading">About Spa</div>


</div>
<p>Dss Spa, is one of the best massage spa in Singapore. We offer full body massage services including Warm Oil Massage and Relaxing Massage to completely unwind your body, mind, and soul and luxury, is the essential destination to experience a Spa Journey that is a cradle of traditional Arabian customs and modern practices of Aromatherapy and first-class technologically advanced facial treatments. Relive the legend of Cleopatra and tales of the Velvet Nights with our selection of therapies.</p>
<a href="/about" className="btn btn-readmore">Read More</a>

									</div>

								</div>
								</div>
							</div>
						</div>


					</section>

{(shop_our_services.success == 1) ?
								<ShopOurServices services={shop_our_services.cat}  />
								: '' }

				{(get_category.success == 1) ?
					<Categorywise categories={get_category} />
					: '' }
				
								
				

								{(top_shopping_offers.success == 1) ?
								<TopShoppingOffers offers={top_shopping_offers.offers}  />
								: '' }


							
					<section className="slide-wrapper offers-deals">
						<div className=" row justify-content-md-center m-0">
					{(fashion_offers.success == 1) ?
								<FashionOffers offers={fashion_offers.offers}  />
								: '' }

{(today_deals.success == 1) ?
								<TodayDeals deals={today_deals.deals}  />
								: '' }	
				</div>
				</section>
			
					
					
				
			

				{(top_of_the_day.success == 1) ?
					<TopOfTheDay offers={top_of_the_day.offers}  />
					: '' }

				{(featured_products.success == 1) ?
					<FeaturedProducts products={featured_products.products} />
					: '' }

					
				{(promo_banners.success == 1) ?
					<PromoBanner slides={promo_banners.sliders} />
					: '' }					
					
				
					
				
										
				{(get_brand.success == 1) ?
					<Brand brands={get_brand.brands} />
					: '' }


<section className="slide-wrapper testimonial-homepage">
					<div className="slide-holder container">
					  <div className="slider-title">
					  <h4 className="title-head">{this.state.headerName}</h4>
					  <h4 className="title-subhead">{this.state.subheaderName}</h4>
						<div className="title-options">
						  
						</div>

					  </div>

<OwlCarousel
                    className="owl-theme mt-20"
                    nav={true}
                    autoplay
                    margin={10}
                    dots={false}
                    items={5}
                    responsive={this.state.responsive}                    
                >
 {(pagedata.length > 0) ? <>
						
              
					
						{(pagedata.map((info, index) => 						
								<div className="testimonial_cmt_block">
									<div className="cmt_holder">
										<div className="cmt_header">
										<div className="cmt_userimg_wrapper"><img className="big-img img-fluid" src={TESTIMONIAL_IMAGE_URL + info.image} alt={info.name} onError={(e)=>{e.target.src=DEFAULT_IMG}} /></div>
										<div className="cmt_username">{info.name} </div>
										</div>
										<div className="cmt_descp"><p>{info.description}</p> </div>
									</div>
								</div>
						))}
					</> : <div className="page-error"> Testimonial not found </div> }
							
							
							
					
					</OwlCarousel>
					</div>
					</section>
					
				<Footer/>
				
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	get_category : state.items.get_category,
	promo_slides : state.promo_slides.promo_slides,
	promo_banners : state.promo_slides.promo_banners,
	get_brand : state.items.get_brand,	
	top_shopping_offers : state.items.top_shopping_offers,	
	shop_our_services : state.items.shop_our_services,	
	today_deals : state.items.today_deals,	
	fashion_offers : state.items.fashion_offers,	
	featured_products : state.items.featured_products,	
	top_of_the_day : state.items.top_of_the_day,	
	// languages : state.languages.languages,
});
	

export default connect(	mapStateToProps,{
	getCategory,
	// getSingleLanguageData,
	getPromoSlides,
	getPromoBanners,
	getBrand,
	topShoppingOffers,
	shopOurServices,
	todayDeals,
	fashionOffers,
	featuredProducts,
	topOfTheDay,
})(Home);
		
		
