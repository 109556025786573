// export const WEBSITE_URL = "https://bigbasket.com.sg";
//export const WEBSITE_URL = "https://staging.bigbasket.com.sg";

//export const WEBSITE_URL = "http://spademo.gbair.net/spa1.0/admin";
//export const WEBSITE_URL = "http://localhost/spademo/admin";

//export const IMAGE_URL = "http://spademo.gbair.net/spa1.0/admin/assets";


export const WEBSITE_URL = "https://spa.otademo.com/spa1.0/admin";
export const IMAGE_URL = "/spa1.0/admin/assets";